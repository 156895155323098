import React from "react";
import styled from "styled-components";
import cmsService from "../services/cmsService";
import { Section, colors, breakpoints } from "../styles";

import dinnerPdf from "../assets/menus/Ciccios_Dinner_Menu_2024-10-23.pdf";
import winePdf from "../assets/menus/Ciccios_Wine_Menu_2024.11.14.pdf";
import patelliPdf from "../assets/menus/Ciccios_Drink_Patellis_Winter_2018.pdf";
import cockTailsPdf from "../assets/menus/Ciccios_Aperitivo_menu_A5_2024.06.14.pdf";

const ListSection = styled(Section)`
  flex-direction: column;
  align-items: center;
  background: ${colors.red};
  text-transform: uppercase;
  font-family: "CentralAvenue";
`;

const MenuHeading = styled.h3`
  padding-bottom: 0.05rem;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 1.7rem;
  border-bottom: 2px solid ${colors.dark};
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0.5rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.7rem;
  line-height: 1;
  li {
    text-align: center;
    + li {
      @media (${breakpoints.tablet.max}) {
        margin-top: 0.3em;
      }
    }
  }
`;

const MenuLink = styled.a`
  color: inherit;
  text-decoration: none;
  text-transform: none;
  &:hover {
    text-decoration: underline;
  }
`;

class MenuesList extends React.Component {
  state = {
    menus: [
      { name: "DINNER", url: dinnerPdf },
      { name: "WINE and BUBBLES", url: winePdf },
      { name: "COCKTAILS", url: cockTailsPdf },
    ],
  };
  componentDidMount() {
    let menus = [];
    // cmsService
    //   .getMenuUrls()
    //   .then((res) => {
    //     menus = this.setCdnAddresses(
    //       res.data.map((m) => {
    //         return {
    //           name: m.acf.display_name,
    //           url: m.acf.menu,
    //         };
    //       })
    //     );
    //     this.setState({ menus });
    //   })
    //   .catch((err) => console.log(err));
  }

  setCdnAddresses = (postedMenus) =>
    postedMenus.map((menu) => {
      return {
        ...menu,
        url: menu.url
          .replace("italiano.se", cmsService.sslCdnDomain)
          .replace("italianostage.wpengine.com", cmsService.cdnDomain),
      };
    });

  render() {
    return (
      <ListSection id="menus">
        <MenuHeading>Menus</MenuHeading>
        <List>
          {this.state.menus.length > 0 ? (
            this.state.menus.map((menu, i) => (
              <li key={`${menu.name}-${i}`}>
                <MenuLink href={menu.url} target="_blank">
                  {menu.name}
                </MenuLink>
              </li>
            ))
          ) : (
            <li>Coming soon</li>
          )}
        </List>
      </ListSection>
    );
  }
}

export default MenuesList;
